$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 0; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function(){
        $(this).toggleClass("active");
        $(".js-navmobile").toggleClass("active");
        $(".js-header").toggleClass("navOpen");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * marquee
    // *
    // *
    var $marquee = $(".js-marquee");

    if ($marquee.length) {
        $marquee.each(function(){
            var marqueeSpeed = $(this).find(".js-marquee-item:first-child").text().length * 800;
            
            $(this).slick({
                fade: false,
                dots: false,
                arrows: false,
                autoplay: true,
                waitForAnimate: false,
                slidesToScroll: 1,
                variableWidth: true,
                draggable: false,
                pauseOnHover: false,
                rows: 0,
                infinite: true,
                speed: marqueeSpeed,
                autoplaySpeed:0,
                cssEase: 'linear',
            });
        });
    } 
    
    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * productslider
    // *
    // *
    $('.js-productslider-images').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        infinite: false,
        asNavFor: '.js-productslider-thumbs'
    });
    $('.js-productslider-thumbs').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical:true,
        asNavFor: '.js-productslider-images',
        dots: false,
        arrows: false,
        focusOnSelect: true,
        verticalSwiping:true,
        infinite: false
    });
    

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cardCarousel
    // *
    // *
    var $cardCarousel = $(".js-cardcarousel");

    if ($cardCarousel.length) {
        var $cardCarouselSettings = {
            dots: false,
            arrows: true,
            prevArrow: (".js-cardcarousel-prev"),
            nextArrow: (".js-cardcarousel-next"),
            centerMode: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 99999,
                    settings: "unslick"
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };
    
        // Init Slider
        $cardCarousel.slick($cardCarouselSettings);

        // remove overlay while swiping
        $cardCarousel.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            $(".js-card").removeClass("active");
        });
    
        // Reslick only if it's not slick()
        $(window).on('resize', function() {
            if (!$cardCarousel.hasClass('slick-initialized')) {
            return $cardCarousel.slick($cardCarouselSettings);
            }
        });
    }    
    
    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * schedule
    // *
    // *
    var $schedule = $(".js-schedulecarousel");

    if ($schedule.length) {
        
        var $scheduleSettings = {
            fade: false,
            dots: false,
            arrows: true,
            waitForAnimate: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: false,
            infinite: false,
            speed: 1000,
            prevArrow: $(".js-schedulecarousel-prev"),
            nextArrow: $(".js-schedulecarousel-next"),
            responsive: [
                {
                    breakpoint: 1001,
                    settings: "unslick"
                }
            ]
        };

        // Init Slider
        $schedule.slick($scheduleSettings);
   
        // Reslick only if it's not slick()
        $(window).on('resize', function() {
            if (!$schedule.hasClass('slick-initialized')) {
            return $schedule.slick($scheduleSettings);
            }
        });


    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * card
    // *
    // *
    // touchscreen only
    if(window.matchMedia("(pointer: coarse)").matches) {
        $(".js-card").on("click", function() {
            $(this).toggleClass("active");
        })
    } else {
        $(".js-card").on("mouseenter", function() {
            $(this).addClass("active");
        });
        
        $(".js-card").on("mouseleave", function() {
            $(this).removeClass("active");
        });
    }


    if($(window).outerHeight() == $("body").outerHeight()) {
        $(".js-footer").addClass("show");
    }

    
        


});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * footer 
// *
// *
$(window).on("load scroll resize", function(){

    var windowHeight = $(window).outerHeight(),
        bodyHeight = $("body").outerHeight(), 
        scrollTop = $(window).scrollTop();

    if($(window).outerHeight() == $("body").outerHeight()) {
        $(".js-footer").addClass("show");
    }
    
    if((windowHeight + scrollTop + 10) >= bodyHeight) { // 1px for edge cases
        $(".js-footer").addClass("show");
    }else {
        $(".js-footer").removeClass("show");
    }
});
